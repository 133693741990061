import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import Container from "../components/container";

import PageHeader from "../components/page-header";
import LibraryList from "../components/library-list";

import * as styles from "../styles/components/library-archive.module.css";

export const query = graphql`
  query LibraryPageQuery {
    library: sanityLibraryArchive {
      pageHeader{
        _rawHeading
        description
      }
    }
  }
`;

const LibraryPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const library = (data || {}).library;

  return (
    <Layout>
      <Seo
        title="The Chip Wilson Library"
        description={library.pageHeader.description}
      />

      {library.pageHeader && (
        <PageHeader {...library.pageHeader} />
      )}

      <div className={styles.root}>
        <Container><LibraryList /></Container>
      </div>

    </Layout>
  );
};

export default LibraryPage;

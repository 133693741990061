import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { cn } from "../lib/helpers";
import InfoBox from "./info-box";

import * as styles from "../styles/components/library-list.module.css";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query LibraryQuery {
    library: allSanityLibrary(
      sort: { fields: [category___title], order: ASC }
      filter: { title: { ne: null } }
    ) {
      group(field: category___title) {
        fieldValue
        edges {
          node {
            id
            title
            infoBoxHeading
            description
            cover {
              ...SanityImage
              alt
            }
          }
        }
      }
    }
  }
`;

const LibraryList = (props) => {

  return (
    <div className={styles.root}>

      <StaticQuery
        query={query}
        render={data => (

        <>

        {data.library.group.map(( { fieldValue, edges } ) => {

          return (

            <div className={styles.group} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
              {fieldValue && (
                <h2 className={cn("h4", styles.sectionTitle)}>{fieldValue}</h2>
              )}
              <ul>

                {edges.map(( { node: libraryItem } ) => {

                  return (

                    <li key={libraryItem.id} className={styles.item}>
                      {libraryItem.title && (
                        <h3 className={cn("h3", styles.itemTitle)}>{libraryItem.title}</h3>
                      )}
                      {(libraryItem.infoBoxHeading || libraryItem.description || (libraryItem.cover && libraryItem.cover.asset)) && (
                        <InfoBox {...libraryItem} />
                      )}
                    </li>

                  )

                })}

              </ul>
            </div>

          )

        })}

        </>

      )} />

    </div>
  );
}

export default LibraryList;
